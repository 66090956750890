import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import UpNext from '@webMolecules/UpNext/UpNext';
import * as Route from '@routes';
import * as styles from './privacyPolicy.module.scss';

export interface PrivacyPolicyProps {
  title: string;
  body: string;
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({ title, body }) => {
  return (
    <Box>
      <PageHeading title={title} />
      <Container>
        <Box className={styles.privacyPolicyInner} htmlContent={body} />
      </Container>

      <UpNext
        links={[
          { label: 'Our process', href: Route.ourProcess() },
          { label: 'Modular homes', href: Route.designs() },
        ]}
      />
    </Box>
  );
};

export default PrivacyPolicy;
